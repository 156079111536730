import React from 'react'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

export default function IntNumber(props) {

  return (
    <PhoneInput
      international
      limitMaxLength={true}
      countryCallingCodeEditable={false}
      defaultCountry="US"
      value={props.value}
      onChange={props.onChange}
      className="input"
    >
    </PhoneInput>
  )
}
