import React, { Component } from 'react';
import SignaturePreview from './signaturePreview'
import logo from './images/bixal-logo-red.png'

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      browser: '',
      browserCheck: false
    };
  }

  componentWillMount = () => {
    const ua = navigator.userAgent.match(/(opera|chrome|safari|firefox|msie)\/?\s*/i);
    if (ua[1] !== 'Chrome') {
      this.setState({
        browser: 'Looks like you are running ' + ua[1] + '. This process works best if you are using Chrome 42+ or higher. :)',
        browserCheck: true
      })
    }
  }

  render() {
    return (
      <div className="app-wrapper">
        {this.state.browserCheck === true ? (
          <div className="browser-alert">
            <div className="browser-alert--content">{this.state.browser}</div>
          </div>
        ) : null}
        <a class="skip-main" href="#main">Skip to main content</a>
        <header className="top-banner">
          <div className="top-banner--content">
            <img id="logo" alt="bixal logo" src={logo} />
            </div>
        </header>
        <div className="content-wrapper">
          <h1 className="heading">Build Your Bixal Email Signature</h1>
          <SignaturePreview />
          <div className="scroll-on">
            <div className="scroll-on--content">

            </div>
          </div>
        </div>
        <footer className="footer">
          <div className="footer-content">
            <div className="footer-content--branding">
              <div className="icon-b"></div>
              <div style={{ fontSize: '11px' }}>Copyright &#169; {new Date().getFullYear()} Bixal</div>
            </div>
            <div className="footer-content--howto">
              <h2>Quick Links</h2>
              <ul>
                <li>
                  <a href="https://support.office.com/en-us/article/create-and-add-an-email-signature-in-outlook-web-app-0f230564-11b9-4239-83de-f10cbe4dfdfc">
                    Outlook Web App
              </a>
                </li>
                <li>
                  Outlook Desktop App ({" "}
                  <a href="https://support.office.com/en-us/article/create-and-insert-a-signature-in-outlook-for-mac-f4d21492-0956-4429-95ad-2769745b539c">
                    Mac
              </a>{" "}
                  |{" "}
                  <a href="https://support.office.com/en-us/article/create-and-add-a-signature-to-messages-8ee5d4f4-68fd-464a-a1c1-0e1c80bb27f2">
                    PC
              </a>{" "}
                  )
            </li>
                <li>
                  <a href="https://support.apple.com/guide/mail/create-and-use-email-signatures-mail11943/mac">
                    Mac Mail
              </a>
                </li>
                <li>
                  <a href="https://support.google.com/mail/answer/8395?co=GENIE.Platform%3DDesktop&hl=en">
                    GMail
              </a>
                </li>
              </ul>
          </div>
            <div className="footer-content--help">
              <h2>Having trouble?</h2>
              <a href="https://help.bixal.com/#login" target="_blank" rel="noopener noreferrer">help.bixal.com</a>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default App;
