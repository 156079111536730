import React, { Component } from 'react';
import Clipboard from 'react-clipboard.js';
import InputMask from 'react-input-mask';
import IntNumber from './IntNumber'
import { formatPhoneNumberIntl } from 'react-phone-number-input'

//validations
const numberErrorMsg = {
  'color' : '#e02f00',
  'fontSize':'12px',
  'fontWeight':'900'
}

//signature preview
//these need to be rendered inline

//table
const tableStyle = {
  'fontFamily':'Arial, sans-serif, "Open Sans"',
  'fontSize':'10pt',
  'fontWeight':'normal',
  'color':'#5f6062',
  'lineHeight':'normal',
  'margin':'0px'
}

const imgStyle = {
  'fontFamily':'Arial, sans-serif, "Open Sans"',
  'maxWidth':'100px',
  'fontSize':'16px',
  'background':'#FFFFFF'
}

const nameStyle = {
  'fontFamily':'Arial, sans-serif, "Open Sans"',
  'fontSize':'10pt',
  'fontWeight':'bold',
  'color':'#5f6062',
  'lineHeight':'normal',
  'margin':'0px 0px 4px 0px',
  'padding': '10px 0px 0px 0px',
  'borderTop': '1px solid lightgrey'
}

const positionStyle = {
  'fontFamily':'Arial, sans-serif, "Open Sans"',
  'fontSize':'9pt',
  'color':'#5f6062',
  'lineHeight':'normal',
  'margin':'0px 0px 2px 0px'
}

const paragraphStyle = {
  'fontFamily':'Arial, sans-serif, "Open Sans"',
  'textAlign':'left',
  'fontSize':'9pt',
  'margin':'0px 0px 2px 0px',
  'width': '220px'
}

const lastParagraphStyle = {
  'fontFamily':'Arial, sans-serif, "Open Sans"',
  'textAlign':'left',
  'fontSize': '9pt',
  'margin':'0px 0px 10px 0px'
}

const linkWrapperStyle = {
  'fontFamily':'Arial, sans-serif, "Open Sans"',
  'padding':'10px 0px 0px 0px',
  'borderTop': '1px solid lightgrey'
}

class SignaturePreview extends Component {

  constructor(props) {
    super(props);
    this.state = {
      textStyle: 'HTML',
      isTextStyleChecked: false,
      title: 'Name',
      pronouns: '',
      position: 'Position',
      mobileNumber: '000.000.0000',
      emailName: 'human@bixal.com',
      isIntlChecked: false,
      isMobileChecked: true,
      isDCNumberChecked: false,
      officeHQNumber: '703.634.5701',
      officeDCNumber: '202.808.9591',
      successMsg: false,
      numberErrorMsg: false,
      color: 'red',
      hexColor: "#e02f00"
    };
  }

  handleTextStyleCheck = (event) => {
    let textStyle = "";

    if (event.target.value === "true") {
      textStyle = 'HTML';
    } else {
      textStyle = 'Plain Text';
    }
    this.setState({
      textStyle: textStyle,
      isTextStyleChecked: !this.state.isTextStyleChecked,
      successMsg: false
    })
  }

  handleTitleChange = (event) => {
    this.setState({
      title: event.target.value,
      successMsg: false
    })
  }

  handlePronounsChange = (event) => {
    this.setState({
      pronouns: event.target.value,
      successMsg: false
    })
  }

  handlePositionChange = (event) => {
    this.setState({
      position: event.target.value,
      successMsg: false
    })
  }

  handleEmailChange = (event) => {
    this.setState({
      emailName: event.target.value,
      successMsg: false
    })
  }

  handleDcNumberCheck = () => {
    this.setState({
      isDCNumberChecked: !this.state.isDCNumberChecked,
      successMsg: false
    })
  }

  handleMobileChecked = () => {
    this.setState({
      isMobileChecked: !this.state.isMobileChecked,
      successMsg: false
    })
  }

  handleIntlChecked = () => {
    this.setState({
      isIntlChecked: !this.state.isIntlChecked,
      mobileNumber: "",
      successMsg: false,
      numberErrorMsg: false
    })
  }

  handleColorChange = (event) => {
    let color = event.target.value;
    let hexColor = "";

    switch (color) {
      case 'black':
        hexColor = "#000000";
        break;
      case 'purple':
        hexColor = "#7d0096";
        break;
      case 'navy':
        hexColor = "#172d6a";
        break;
      default:
        hexColor = "#e02f00";
    }

    this.setState({
      hexColor: hexColor,
      color: color,
      successMsg: false
    });
  }

  handleMobileNumberChange = (event) => {
    let number = event.target.value
    const regex = /^(\d{3})(\d{3})(\d{4})$/g
    const numberStyled = number.replace(regex, '$1.$2.$3')

    if (!event.target.value.match(/[0-9]/)) {
      this.setState({
        mobileNumber: numberStyled,
        numberErrorMsg: true
       })
    } else {
      this.setState({
        mobileNumber: numberStyled,
        successMsg: false,
        numberErrorMsg: false
      })
    }
  }

  handleIntlNumber = (value) => {
    let number = formatPhoneNumberIntl(value)
    const regex = /\s/g
    let numberStyled = number.replace(regex, '.')

    this.setState({
      mobileNumber: numberStyled,
    })
  }

  handleCipboardSuccess = () => {
    this.setState({
      successMsg: true,
    })
  }

  render() {
             //this is going to show an "Unexpected string concatenation of literals" error, but we need to inject this to our header for outlook pc. We need to reference this in the styles so that outlook knows how to render our font consistently. [litmus.com](https://litmus.com/community/discussions/36-outlook-and-fallback-fonts)
             // eslint-disable-next-line
             function handleHeaderStyles() {
               // eslint-disable-next-line
               return {
                 __html:
                   "[" +
                   'style*="Open Sans"' +
                   "] " +
                   "{" +
                   // eslint-disable-next-line
                   "font-family: Arial, sans-serif, " +
                   "Open Sans" +
                   " !important;" +
                   // eslint-disable-next-line
                   "}"
               };
             }

             return (
               <div className="container">
                 <div className="container-signature">
                   <div className="email-heading">
                     <div className="email-heading-features">
                       <span className="email-heading-feature"></span>
                       <span className="email-heading-feature"></span>
                       <span className="email-heading-feature"></span>
                     </div>
                   </div>
                   <div className="email-heading-line">
                     <span className="email-heading-line--item">To:</span>
                     <span className="email-heading-line--item">Cc:</span>
                     <span className="email-heading-line--item">Subject:</span>
                   </div>
                   <div className="container-signature-preview">
                     {/* this is going to show a child error but we need the element in our clipboard */}
                     {this.state.isTextStyleChecked === false ?
                     <html id="signature-preview" lang="en">
                       <head>
                         <style
                           dangerouslySetInnerHTML={handleHeaderStyles()}
                         ></style>
                       </head>
                       <table style={tableStyle}>
                         <tbody>
                           <tr width="100">
                               <td width="100" style={{
                                 padding: '0px 0px 10px 0px'
                               }}>
                               <img
                                 style={imgStyle}
                                 src={
                                   "https://bixal-assets.s3.amazonaws.com/email/bixal-logo-email-" +
                                     this.state.color +
                                     ".png"}
                                 alt="Bixal"
                                 width="100"
                                 maxwidth="100"
                               />
                             </td>
                           </tr>
                           <tr>
                             <td>
                               <p style={nameStyle}>{this.state.title}{this.state.pronouns.length > 0 ? ` (${this.state.pronouns})` : null }</p>
                               <p style={positionStyle}>
                                 {this.state.position}
                               </p>
                             </td>
                           </tr>
                           {this.state.isMobileChecked === true ? (
                             <tr>
                               <td>
                                 <p style={paragraphStyle}>
                                   <strong
                                     style={{
                                       fontSize: "7pt"
                                     }}
                                   >
                                     M:{" "}
                                   </strong>
                                   {this.state.mobileNumber}
                                 </p>
                               </td>
                             </tr>
                           ) : null}
                           <tr>
                             <td>
                               <p style={paragraphStyle}>
                                 <strong
                                   style={{
                                     fontSize: "7pt"
                                   }}
                                 >
                                   O:{" "}
                                 </strong>
                                 {this.state.isDCNumberChecked === true
                                   ? this.state.isIntlChecked ? '+1.' + this.state.officeDCNumber : this.state.officeDCNumber
                                   : this.state.isIntlChecked ? '+1.' + this.state.officeHQNumber : this.state.officeHQNumber }
                               </p>
                             </td>
                           </tr>
                           <tr>
                             <td>
                               <p style={lastParagraphStyle}>
                                 <strong
                                   style={{
                                     fontSize: "7pt"
                                   }}
                                 >
                                   E:{" "}
                                 </strong>
                                 {this.state.emailName}
                               </p>
                             </td>
                           </tr>
                           <tr>
                             <td style={linkWrapperStyle}>
                               <a
                                 href="https://www.bixal.com"
                                 style={{
                                   color: this.state.hexColor,
                                   fontWeight: "bold",
                                   textDecoration: "none"
                                 }}
                               >
                                 bixal.com
                               </a>
                             </td>
                           </tr>
                         </tbody>
                       </table>
                     </html>
                     :
                       <div id="signature-preview">
                        <div>Bixal</div>
                        <div>{this.state.title}{this.state.pronouns.length > 0 ? ` (${this.state.pronouns})` : null }</div>
                        <div>{this.state.position}</div>
                        <div>{this.state.isMobileChecked === true
                          ? 'M: ' + this.state.mobileNumber + ''
                          : null}</div>
                        <div>{this.state.isDCNumberChecked === true
                          ? this.state.isIntlChecked ? 'O: +1.' + this.state.officeDCNumber + '' : 'O: ' + this.state.officeDCNumber + ''
                          : this.state.isIntlChecked ? '0: +1.' + this.state.officeHQNumber + '' : 'O: ' + this.state.officeHQNumber + '' }</div>
                        <div>bixal.com</div>
                      </div>
                      }
                   </div>
                 </div>
                 <div className="container-form">
                   <form id="main" tabindex="-1">
                     <div className="text-toggle">
                       <span id="togglelabel" class="hidden-label">Toggle HTML or Plain Text</span>
                       <label class="switch">
                         <input
                          type="checkbox"
                          id="textstyle"
                          defaultChecked={this.state.isTextStyleChecked}
                          value={this.state.isTextStyleChecked}
                          onChange={this.handleTextStyleCheck}
                          aria-labelledby="togglelabel"></input>
                         <span class="slider round"></span>
                       </label>
                       <span className="text-toggle--description">{this.state.textStyle}</span>
                     </div>
                     <label className="hidden-label" htmlFor="name">Name</label>
                     <input
                       id="name"
                       type="text"
                       placeholder="Full Name"
                       onChange={this.handleTitleChange}
                     ></input>
                     <label className="hidden-label" htmlFor="position">Pronouns (optional)</label>
                      <input
                       id="pronouns"
                       type="text"
                       placeholder="Pronouns (optional)"
                       onChange={this.handlePronounsChange}
                     ></input>
                     <label className="hidden-label" htmlFor="position">Position</label>
                     <input
                       id="position"
                       type="text"
                       placeholder="Position"
                       onChange={this.handlePositionChange}
                     ></input>
                     <label className="hidden-label" htmlFor="email">Email</label>
                     <input
                       id="email"
                       type="email"
                       placeholder="Email"
                       onChange={this.handleEmailChange}
                     ></input>
                      {this.state.isIntlChecked && this.state.isMobileChecked ?
                      <span>
                       <label className="hidden-label" htmlFor="mobilenumber">International Mobile Number</label>
                       <IntNumber
                        className="input"
                        placeholder="Mobile Contact"
                        value={""}
                        onChange={this.handleIntlNumber}/>
                      </span> :
                        null
                       }
                     <span>
                       <label className="hidden-label" htmlFor="mobilenumber">Mobile Number</label>
                        <InputMask
                          id="mobilenumber"
                          type="tel"
                          name="usertel"
                          mask={"999.999.9999"}
                          maskChar=""
                          className="input"
                          placeholder="Mobile Contact"
                          hidden={this.state.isMobileChecked && !this.state.isIntlChecked ? false : true }
                          onChange={this.handleMobileNumberChange}
                        ></InputMask>
                       {this.state.numberErrorMsg === true ? (
                         <label style={numberErrorMsg} htmlFor="mobilenumber">
                           * Must be ten digit number
                         </label>
                       ) : null}
                     </span>
                     <fieldset>
                       <legend hidden>Customize:</legend>
                       {this.state.isTextStyleChecked === false ?
                         <div>
                           <label htmlFor="logo-select">
                             Choose your Bixal color
                         </label>
                           <select
                             id="logo-select"
                             onChange={this.handleColorChange}
                           >
                             <option value="red">Red</option>
                             <option value="purple">Purple</option>
                             <option value="navy">Navy</option>
                             <option value="black">Black</option>
                           </select>
                         </div> : <div>
                           <label htmlFor="logo-select" hidden>
                             Choose your Bixal color
                         </label>
                           <select
                             hidden
                             id="logo-select"
                             onChange={this.handleColorChange}
                           >
                             <option value="red">Red</option>
                             <option value="orange">Orange</option>
                             <option value="teal">Teal</option>
                             <option value="navy">Navy</option>
                           </select>
                         </div>}
                      <div>
                         <input
                           type="checkbox"
                           id="intlnumber"
                           defaultChecked={this.state.isIntlChecked}
                           value={this.state.isIntlChecked}
                           onChange={this.handleIntlChecked}
                         ></input>
                         <label htmlFor="intlnumber">Display International Numbers</label>
                       </div>
                       <div>
                         <input
                           type="checkbox"
                           id="mobile"
                           defaultChecked={this.state.isMobileChecked}
                           value={this.state.isMobileChecked}
                           onChange={this.handleMobileChecked}
                         ></input>
                         <label htmlFor="mobile">Display Mobile Contact</label>
                       </div>
                       <div>
                         <input
                           type="checkbox"
                           id="officenumber"
                           defaultChecked={this.state.isDCNumberChecked}
                           value={this.state.isDCNumberChecked}
                           onChange={this.handleDcNumberCheck}
                         ></input>
                         <label htmlFor="officenumber">
                           Display DC Office Number
                         </label>
                       </div>
                     </fieldset>
                     <Clipboard
                       className="button-clipboard-copy"
                       data-clipboard-action="copy"
                       data-clipboard-target="#signature-preview"
                       onSuccess={this.handleCipboardSuccess}
                     >
                       Copy to Clipboard
                     </Clipboard>
                     <br/>
                     {this.state.successMsg === true ? (
                       <span className="sucess-msg">
                         Success! Signature Copied.
                       </span>
                     ) : null}
                   </form>
                 </div>
               </div>
             );
           }
}

export default SignaturePreview;
